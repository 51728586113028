import React from 'react';
import LinkedinCard from './LinkedinCard/LinkedinCard';
import Tooltip from '../../UI/Tooltip';

export default () => (
  <div className="container">
    <div className="row justify-content-center align-items-center m-0">
      <div className="col-12 p-0">
        <h1 className="text-center latoBlack main-heading mb-3">Our leadership</h1>
        <div className="about-our-leaders__grid--top">
          <LinkedinCard
            name="Murad Akhter"
            position="CEO &amp; Co-Founder"
            linkedinURL="https://www.linkedin.com/in/muradakhter/"
            imgSrc={require('../../../assets/images/company/our-leaders/murad-akhtar.svg').default}
            eventName="Murad Akhter LinkedIn"
            eventValue="Murad Akhter Profile on LinkedIn"
          />
          <LinkedinCard
            name="Mannan Amin"
            position="Co-Founder &amp; President"
            linkedinURL="https://www.linkedin.com/in/mannanamin/"
            imgSrc={require('../../../assets/images/company/our-leaders/mannan-amin.svg').default}
            eventName="Mannan Amin LinkedIn"
            eventValue="Mannan Amin Profile on LinkedIn"
          />
          <LinkedinCard
            name="Faried Nawaz"
            position="CTO"
            linkedinURL="https://www.linkedin.com/in/fariednawaz/"
            imgSrc={require('../../../assets/images/company/our-leaders/faried-nawaz.svg').default}
            eventName="Faried Nawaz LinkedIn"
            eventValue="Faried Nawaz Profile on LinkedIn"
          />
          <LinkedinCard
            name="William Schleifer"
            position="VP Client Engagement, North America"
            linkedinURL="https://www.linkedin.com/in/williamschleifer/"
            imgSrc={
              require('../../../assets/images/company/our-leaders/william-schleifer.svg').default
            }
            eventName="William Schleifer LinkedIn"
            eventValue="William Schleifer Profile on LinkedIn"
          />
          <LinkedinCard
            name="Fahd Gilani"
            position="SVP Engineering"
            linkedinURL="https://www.linkedin.com/in/fgilani/"
            imgSrc={require('../../../assets/images/company/our-leaders/fahad-gilani.svg').default}
            eventName="Fahd Gilani LinkedIn"
            eventValue="Fahd Gilani Profile on LinkedIn"
          />

          <LinkedinCard
            name="Abdul Wasay"
            position="VP Engineering"
            linkedinURL="https://www.linkedin.com/in/a-wasay/"
            imgSrc={require('../../../assets/images/company/our-leaders/abdul-wasay.svg').default}
            eventName="Abdul Wasay LinkedIn"
            eventValue="Abdul Wasay Profile on LinkedIn"
          />

          <LinkedinCard
            name="Faizan Rasool"
            position="VP Marketing &amp; Sales"
            linkedinURL="https://www.linkedin.com/in/faizan-rasool-93b74aa/"
            imgSrc={require('../../../assets/images/company/our-leaders/faizan-rasool.svg').default}
            eventName="Faizan Rasool LinkedIn"
            eventValue="Faizan Rasool Profile on LinkedIn"
          />

          <LinkedinCard
            name="Mohammed Ali"
            position="VP Hardware"
            linkedinURL="https://www.linkedin.com/in/mdotali/"
            imgSrc={require('../../../assets/images/company/our-leaders/m-ali.svg').default}
            eventName="Mohammed Ali LinkedIn"
            eventValue="Mohammed Ali Profile on LinkedIn"
          />
          {/* <LinkedinCard
            name="Jazib Zahir"
            position="Partner"
            linkedinURL="https://www.linkedin.com/in/jazib-zahir-b629513/"
            imgSrc={require('../../../assets/images/company/our-leaders/jazib-zahir.webp').default}
            eventName="Jazib Zahir LinkedIn"
            eventValue="Jazib Zahir Profile on LinkedIn"
          />
          <LinkedinCard
            name="Syed Pirzada"
            position="GM Canada"
            linkedinURL="https://www.linkedin.com/in/syedpirzada/"
            imgSrc={require('../../../assets/images/company/our-leaders/syed-pirzada.webp').default}
            eventName="Syed Pirzada LinkedIn"
            eventValue="Syed Pirzada Profile on LinkedIn"
          /> */}

          {/* <LinkedinCard
            name="Abida Nagra"
            position="Partner, Scandinavia"
            linkedinURL="https://www.linkedin.com/in/abidanagra/"
            imgSrc={require('../../../assets/images/company/our-leaders/abida-nagra.webp').default}
            eventName="Abida Nagra LinkedIn"
            eventValue="Abida Nagra Profile on LinkedIn"
          /> */}
        </div>
      </div>
    </div>
  </div>
);
