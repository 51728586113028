import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';
import {Event} from '../../../GAClickEvent';
import * as classes from './LinkedinCard.module.css';

export default function LinkedinCard({linkedinURL, eventName, eventValue, imgSrc, name, position}) {
  // const data = useStaticQuery(graphql`
  //   query {
  //     allFile(filter: {relativeDirectory: {eq: "company/our-leaders"}}) {
  //       edges {
  //         node {
  //           relativePath
  //           childImageSharp {
  //             fluid(width: 179, height: 179, quality: 100) {
  //               ...GatsbyImageSharpFluid_withWebp_noBase64
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // `);

  // const match = React.useMemo(
  //   () =>
  //     data.allFile.edges.find(({node}) => `company/our-leaders/${imgSrc}` === node.relativePath),
  //   [data, imgSrc]
  // );

  // return (
  //   <div
  //     className={`card ${classes.memberCard}`}
  //     onClick={() => {
  //       window.location.href = linkedinURL;
  //       Event(eventName, eventValue, window.location.pathname);
  //     }}
  //   >
  //     <div className={classes.memberImageContainer}>
  //       <Img
  //         className="img-fluid"
  //         imgStyle={{objectFit: 'cover'}}
  //         imgSrc={imgSrc}
  //         placeholderStyle={{objectFit: 'contain'}}
  //         alt={name}
  //         fadeIn={false}
  //         loading="lazy"
  //       />
  //     </div>
  //     <div className="card-body d-flex text-center">
  //       <div className="col-10 p-0">
  //         <h5 className={classes.name}>{name}</h5>
  //         <p className={classes.designation}>{position}</p>
  //       </div>

  //       <a className="col-2" href={linkedinURL}>
  //         <img
  //           className={classes.linkedinImage}
  //           src={require('../../../../assets/images/company/linkedin.svg').default}
  //           alt="Linkedin Logo"
  //         />
  //       </a>
  //     </div>
  //   </div>
  // );

  return (
    <div
      className={`card ${classes.memberCard}`}
      onClick={() => {
        window.location.href = linkedinURL;
        Event(eventName, eventValue, window.location.pathname);
      }}
    >
      <div className="col-12 px-3 pt-3">
        <div className={classes.memberImageContainer}>
          <img className={classes.image} src={imgSrc} alt={name} />
        </div>

        <div className="pt-2 d-flex text-left flex-wrap">
          <div className="col-9 p-0">
            <h5 className={classes.name}>{name}</h5>
            <p className={classes.designation}>{position}</p>
          </div>

          <a
            className="col-3 p-0 text-right"
            href={linkedinURL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className={classes.linkedinImage}
              src={require('../../../../assets/images/company/linkedin.svg').default}
              alt="Linkedin Logo"
            />
          </a>
        </div>
      </div>
    </div>
  );
}
